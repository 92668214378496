// getMessageDuration.js
'use strict';
import { Map } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

import getMessageData from '../selector/getMessageData.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';

export const MAXIMUM_FREE_PEEK_DURATION = 300;

const defaultArray = [];
const defaultMap = new Map();

/**
 * Select message duration by message id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @param {string} pathname - current pathname.
 * @return {number} The selected message duration.
 */
const getMessageDuration = createCachedSelector(
  (state, messageId) => getMessageData(state, messageId, 'mediaDuration'),
  (state, messageId) => getMessageAssetDuration(state, messageId),
  (state, messageId) => getMessageData(state, messageId, 'hasFreePeek'),
  (state, messageId, pathname) => pathname,
  (messageDuration = 0, assetDuration = 0, hasFreePeek, pathname) => {
    const isOnFreezone = getIsMatchedPathname({
      pathname,
      dataKey: 'isOnFreezone',
    });
    const duration = assetDuration || messageDuration;
    if (isOnFreezone && hasFreePeek) {
      return Math.min(duration * 0.45, MAXIMUM_FREE_PEEK_DURATION);
    }
    return duration;
  }
)((state, messageId, pathname) => `${messageId}:${pathname}`);

export default getMessageDuration;

/**
 * Select message asset duration by message id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @return {number} The selected message duration.
 */
const getMessageAssetDuration = createCachedSelector(
  (state, messageId) => getMessageData(state, messageId, 'assetIds'),
  state => state.get('assets'),
  (assetIds = defaultArray, assetMap = defaultMap) => {
    const firstAssetId = assetIds[0];
    return assetMap.getIn([firstAssetId, 'duration']);
  }
)((state, messageId) => `${messageId}`);
