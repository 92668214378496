// FlixListItem.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Observer from '@researchgate/react-intersection-observer';

import WithMessageMetaData from '../container/WithMessageMetaData.js';
import FlixPreview from '../container/FlixPreview.js';
import UserAvatar from '../container/UserAvatar.js';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import formatNumber from '../resource/formatNumber.js';
import { getKeySystem } from '../resource/getDrmInfo.js';
import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';
import HighlightSubstring from '../component/HighlightSubstring.jsx';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';

import PinnedGreyIconSource from '../../img/ic_pin_grey.svg';
import UnLockIconSrc from '../../img/ic_unlock_xs.svg';
import LikeIconSrc from '../../img/ic_like_xs.svg';
import ProIconSrc from '../../img/ic_pro_badge.svg';
import ViewersIconSrc from '../../img/ic_streaming_view_xs.svg';
import DiamondIconSrc from '../../img/ic_diamond_xs.svg';
import ResetHeadingStyle from '../style/ResetHeadingStyle.js';
import media from '../style/media.js';
import { color, breakpoint } from '../style/variables.js';
import { ButtonId } from '../resource/mixpanel.js';
import { ProfileListType } from '../resource/profileConstants.js';
import { archiveType } from '../resource/archiveConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import withViewportItemTracker from '../resource/withViewportItemTracker.js';

const isClient = getIsInBrowserMainThread();

export class FlixListItem extends React.PureComponent {
  state = {
    isHovered: false,
    isIntersecting: false,
  };

  handleIntersection = ({ isIntersecting }, unobserve) => {
    this.setState({ isIntersecting });
    if (isIntersecting) {
      unobserve();
    }
  };

  componentDidMount() {
    this.fetchUser();
    this.fetchMessageDetail();
    this.fetchDrmLicenseAvailability();
  }
  componentDidUpdate(prevProps, prevState) {
    const { senderId, senderName, messageId, canViewMedia, isAuthed } =
      this.props;
    const { isIntersecting } = this.state;
    if (
      prevProps.senderId !== senderId ||
      isIntersecting !== prevState.isIntersecting ||
      senderName !== prevProps.senderName
    ) {
      this.fetchUser();
    }

    if (
      messageId !== prevProps.messageId ||
      canViewMedia !== prevProps.canViewMedia ||
      isIntersecting !== prevState.isIntersecting ||
      (isAuthed && isAuthed !== prevProps.isAuthed)
    ) {
      this.fetchDrmLicenseAvailability();
    }
  }
  fetchUser = () => {
    const { senderId, senderName, fetchUser } = this.props;
    const { isIntersecting } = this.state;
    if (!isIntersecting || !senderId || senderName) {
      return;
    }
    return fetchUser(senderId);
  };
  fetchMessageDetail = () => {
    const { messageId, badges, fetchMessageDetail } = this.props;
    if (messageId && !badges) {
      fetchMessageDetail({ messageId });
    }
  };
  fetchDrmLicenseAvailability = () => {
    const { messageId, canViewMedia, fetchDrmLicenseAvailability } = this.props;
    const { isIntersecting } = this.state;

    if (!messageId || canViewMedia || !isIntersecting) return;

    return fetchDrmLicenseAvailability({
      messageId,
      keySystem: getKeySystem(),
    });
  };
  handleOnMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  getIsShortVideo = () => {
    const { contentType, duration, shortVideoDuration } = this.props;
    const isVideo = contentType.startsWith('video/');
    const isShortVideo =
      // TODO: refactor this to re-use logic
      shortVideoDuration && isVideo && duration < shortVideoDuration;
    return isShortVideo;
  };
  getLocationState = () => {
    const { senderId, shortVideoOrigin } = this.props;
    const isShortVideo = this.getIsShortVideo();
    if (!isShortVideo) {
      return {};
    }
    if ('profile' === shortVideoOrigin) {
      return {
        listPath: ['outbox', ProfileListType.SHORT, senderId],
      };
    }
    if ('archive' === shortVideoOrigin) {
      return {
        listPath: ['unlocked', 'messages', archiveType.SHORT, senderId],
      };
    }
    return {};
  };

  render() {
    const {
      shortVideoOrigin,
      senderName,
      displayedSenderUsername,
      originalTitle,
      originalCaptionText,
      unlockPrice,
      deviceWidth,
      messageId,
      shouldUseLink,
      shouldUseLazyImage,
      shouldTrackRecommendEvent,
      isPro,
      isOnline,
      canViewMedia,
      shouldShowUnlockCount,
      shouldIncrementHeading,
      isPinned,
      shouldUseMobileLayout,
      shouldShowAuthor,
      shouldTrackProfilePostEvent,
      senderId,
      matchedString,
      t,
    } = this.props;
    const { isHovered } = this.state;
    const isOnMobile = deviceWidth <= breakpoint.mobile;

    const getTrackingElementId = () => {
      let buttonId = '';
      if (shouldTrackRecommendEvent) {
        buttonId = ButtonId.Detail.ButtonRecommendFlix;
      }
      if (shouldTrackProfilePostEvent) {
        buttonId = ButtonId.Profile.ButtonPostClick;
      }
      return buttonId;
    };

    const getTrackingPayload = () => {
      const defaultTrackingPayload = {
        messageId,
        'user.username': senderName,
      };
      let extraProps = {};
      if (shouldTrackRecommendEvent) {
        extraProps = {
          ...defaultTrackingPayload,
          'recommend.message.id': messageId,
        };
      }
      if (shouldTrackProfilePostEvent) {
        extraProps = { ...defaultTrackingPayload };
      }
      return extraProps;
    };
    return (
      <Observer onChange={this.handleIntersection}>
        <StyledFlixListItem
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          $shouldUseMobileLayout={shouldUseMobileLayout}
        >
          <PreviewColumn $shouldUseMobileLayout={shouldUseMobileLayout}>
            <FlixPreview
              shouldPlay={isHovered}
              shouldUseLink={shouldUseLink}
              shouldUseLazyImage={shouldUseLazyImage}
              shouldTrackRecommendEvent={shouldTrackRecommendEvent}
              shouldTrackProfilePostEvent={shouldTrackProfilePostEvent}
              messageId={messageId}
              shortVideoOrigin={shortVideoOrigin}
            />
          </PreviewColumn>
          <WithMessageMetaData messageId={messageId}>
            {({ likePercentage, unlocks, viewCount }) => {
              return (
                <InfoColumn>
                  <InfoColumnBody
                    to={`/post/${messageId}${isClient ? location.search : ''}`}
                    state={this.getLocationState()}
                    title={originalTitle}
                    data-element_id={getTrackingElementId()}
                    data-tracking_payload={getTrackingPayload()}
                    onClick={e => {
                      if (shouldUseLink) return;
                      e.preventDefault();
                      this.props.replace(
                        `/post/${messageId}${isClient ? location.search : ''}`
                      );
                    }}
                  >
                    {isPinned && (
                      <Pinned>
                        <PinIcon
                          src={PinnedGreyIconSource}
                          $shouldUseMobileLayout={shouldUseMobileLayout}
                        />
                        <PinText $shouldUseMobileLayout={shouldUseMobileLayout}>
                          {t('pinned_post', {
                            ns: TranslationNamespace.POST,
                          })}
                        </PinText>
                      </Pinned>
                    )}
                    <Title
                      shouldIncrementHeading={shouldIncrementHeading}
                      isPinned={isPinned}
                      $shouldUseMobileLayout={shouldUseMobileLayout}
                      shouldShowAuthor={shouldShowAuthor}
                    >
                      {isPro && (
                        <ProIcon
                          src={ProIconSrc}
                          width="12"
                          height="12"
                          alt="pro icon"
                        />
                      )}
                      <HighlightSubstring substring={matchedString}>
                        {originalTitle || originalCaptionText || '-'}
                      </HighlightSubstring>
                    </Title>
                    <MetaData>
                      <MetaIcon
                        src={
                          !shouldShowUnlockCount
                            ? ViewersIconSrc
                            : UnLockIconSrc
                        }
                        $shouldUseMobileLayout={shouldUseMobileLayout}
                      />
                      <MetaNumber
                        isViewCount={true}
                        $shouldUseMobileLayout={shouldUseMobileLayout}
                      >
                        {!shouldShowUnlockCount && viewCount
                          ? formatNumber(viewCount)
                          : unlocks
                            ? formatNumber(unlocks)
                            : '-'}
                      </MetaNumber>
                      <MetaIcon
                        src={LikeIconSrc}
                        $shouldUseMobileLayout={shouldUseMobileLayout}
                      />
                      <MetaNumber
                        $shouldUseMobileLayout={shouldUseMobileLayout}
                      >
                        {typeof likePercentage === 'number'
                          ? `${likePercentage}%`
                          : '-'}
                      </MetaNumber>
                    </MetaData>
                    {unlockPrice > 0 && !canViewMedia && (
                      <UnlockPriceWrapper>
                        <UnlockPriceImg src={DiamondIconSrc} alt="diamond" />
                        <UnlockPriceText>{unlockPrice}</UnlockPriceText>
                      </UnlockPriceWrapper>
                    )}
                  </InfoColumnBody>
                  {/* Let Author block outside of InfoColumn to avoid nesting <a> error */}
                  {shouldShowAuthor && (
                    <AuthorLink
                      to={`/user/${senderId}/flix`}
                      title={displayedSenderUsername}
                      $shouldUseMobileLayout={shouldUseMobileLayout}
                      data-click_tracking_disabled={
                        shouldTrackRecommendEvent ? false : true
                      }
                      data-element_id={
                        ButtonId.Detail.ButtonRecommendFlixAvatar
                      }
                      data-tracking_payload={getTrackingPayload()}
                    >
                      <Author>
                        <UserAvatar
                          id={senderId}
                          size={isOnMobile || shouldUseMobileLayout ? 16 : 24}
                        />
                        <Username
                          isOnline={isOnline}
                          shouldIncrementHeading={shouldIncrementHeading}
                          $shouldUseMobileLayout={shouldUseMobileLayout}
                        >
                          {displayedSenderUsername}
                        </Username>
                        <UserStatusIndicator
                          userId={senderId}
                          livestreamSize={12}
                          shouldShowLiveStream
                          shouldShowOnline
                        />
                      </Author>
                    </AuthorLink>
                  )}
                </InfoColumn>
              );
            }}
          </WithMessageMetaData>
        </StyledFlixListItem>
      </Observer>
    );
  }
}

FlixListItem.propTypes = {
  messageId: PropTypes.string,
  senderName: PropTypes.string,
  displayedSenderUsername: PropTypes.string,
  senderId: PropTypes.string,
  originalTitle: PropTypes.string,
  originalCaptionText: PropTypes.string,
  unlockPrice: PropTypes.number,
  shortVideoOrigin: PropTypes.string,
  fetchUser: PropTypes.func,
  shouldUseLink: PropTypes.bool,
  shouldUseLazyImage: PropTypes.bool,
  isPro: PropTypes.bool,
  isOnline: PropTypes.bool,
  shouldShowUnlockCount: PropTypes.bool,
  canViewMedia: PropTypes.bool,
  shouldIncrementHeading: PropTypes.bool,
  replace: PropTypes.func,
  fetchDrmLicenseAvailability: PropTypes.func,
  matchedString: PropTypes.string,
  shouldTrackRecommendEvent: PropTypes.bool,
  isPinned: PropTypes.bool,
  shouldUseMobileLayout: PropTypes.bool,
  shouldShowAuthor: PropTypes.bool,
  shouldTrackProfilePostEvent: PropTypes.bool,
  duration: PropTypes.number,
  contentType: PropTypes.string,
  shortVideoDuration: PropTypes.number,
  deviceWidth: PropTypes.number,
  badges: PropTypes.array,
  fetchMessageDetail: PropTypes.func,
  t: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool,
};

FlixListItem.defaultProps = {
  senderId: '',
  senderName: '',
  displayedSenderUsername: '',
  unlockPrice: null,
  shortVideoOrigin: '',
  originalTitle: '',
  originalCaptionText: '',
  shouldUseLink: false,
  shouldUseLazyImage: true,
  isPro: false,
  isOnline: false,
  shouldShowUnlockCount: false,
  canViewMedia: false,
  shouldIncrementHeading: false,
  fetchUser: () => null,
  replace: () => null,
  fetchDrmLicenseAvailability: () => null,
  matchedString: '',
  shouldTrackRecommendEvent: false,
  isPinned: false,
  shouldUseMobileLayout: false,
  shouldShowAuthor: true,
  shouldTrackProfilePostEvent: false,
  duration: null,
  shortVideoDuration: null,
  deviceWidth: 0,
  contentType: '',
  badges: null,
  fetchMessageDetail: () => null,
  isAuthed: false,
};

const StyledFlixListItem = styled.section`
  display: flex;
  height: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 90 : 146}px;
  ${media.mobile`
    height: 90px;
  `};
`;

const PreviewColumn = styled.div`
  min-width: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 140 : 260}px;
  ${media.mobile`
    min-width: 140px;
  `};
`;

const InfoColumn = styled.section`
  margin-left: 12px;
  position: relative;
  flex: 1;

  &:hover {
    opacity: 0.7;

    ${media.mobile`
      opacity: 1;
    `};
  }
`;

const InfoColumnBody = styled(Link)`
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled(
  ({ shouldIncrementHeading, isPinned, shouldShowAuthor, ...restProps }) =>
    shouldIncrementHeading ? <h3 {...restProps} /> : <h2 {...restProps} />
)`
  ${ResetHeadingStyle};
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isPinned, shouldShowAuthor }) =>
    shouldShowAuthor ? 2 : 3 + (isPinned ? -1 : 0)};
  -webkit-box-orient: vertical;
  font-size: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 14 : 18}px;
  line-height: 1.4;
  overflow-y: hidden;
  word-break: break-all;
  ${media.mobile`
    font-size: 14px;
  `};
`;

const AuthorLink = styled(Link)`
  display: block;
  position: absolute;
  bottom: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 20 : 28}px;
  &:hover {
    opacity: 1;
  }
  ${media.mobile`
    bottom: 20px;
  `};
`;

const Author = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;
`;

const MetaData = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  position: absolute;
  bottom: 0;
`;

const ProIcon = styled.img`
  margin-right: 4px;
`;

const MetaNumber = styled.div`
  font-size: ${({ isViewCount, $shouldUseMobileLayout }) =>
    ($shouldUseMobileLayout ? 12 : 16) + (isViewCount ? -1 : 0)}px;
  color: ${color.neutral[100]};
  margin-right: 8px;
  opacity: 0.5;
  ${media.mobile`
    font-size: ${({ isViewCount }) => (isViewCount ? 11 : 12)}px;
  `};
`;

const MetaIcon = styled.img`
  width: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 16 : 24}px;
  height: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 16 : 24}px;
  margin-right: 4px;
  opacity: 0.5;
  ${media.mobile`
    width: 16px;
    height: 16px;
  `};
`;

const Username = styled(({ shouldIncrementHeading, isOnline, ...restProps }) =>
  shouldIncrementHeading ? <h4 {...restProps} /> : <h3 {...restProps} />
)`
  ${ResetHeadingStyle};
  color: ${color.neutral[100]};
  margin-left: 4px;
  margin-right: 4px;
  font-size: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 14 : 20}px;
  font-weight: 400;
  ${media.mobile`
    font-size: 14px;
  `};
`;

const UnlockPriceImg = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 2px;
`;

const UnlockPriceText = styled.span`
  vertical-align: middle;
`;

const UnlockPriceWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  color: ${color.tealBlue};
`;

const Pinned = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PinIcon = styled.img`
  margin-right: 4px;
  width: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 16 : 20}px;
  height: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 16 : 20}px;
  object-fit: contain;
  ${media.mobile`
    width: 16px;
    height: 16px;
  `};
`;

const PinText = styled.div`
  font-size: ${({ $shouldUseMobileLayout }) =>
    $shouldUseMobileLayout ? 12 : 16}px;
  font-weight: 600;
  line-height: 1.4;
  color: ${color.neutral[55]};
  ${media.mobile`
    font-size: 12px;
  `};
`;

export default withViewportItemTracker(FlixListItem);
