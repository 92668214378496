// WithMessageMetaData.jsx
import React from 'react';
import PropTypes from 'prop-types';

export class WithMessageMetaData extends React.PureComponent {
  componentDidMount() {
    this.nextTick = setTimeout(() => {
      const { fetchMessageDetail, messageId, hasFetchedMessageDetail } =
        this.props;
      if (messageId && !hasFetchedMessageDetail) {
        fetchMessageDetail({ messageId });
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { messageId, fetchMessageDetail, hasFetchedMessageDetail } =
      this.props;
    if (
      prevProps.messageId !== messageId &&
      messageId &&
      !hasFetchedMessageDetail
    ) {
      fetchMessageDetail({ messageId });
    }
  }

  render() {
    const { children, likes, unlocks, viewCount, likePercentage, price } =
      this.props;
    return children({ likes, unlocks, viewCount, likePercentage, price });
  }
}

WithMessageMetaData.propTypes = {
  children: PropTypes.func.isRequired,
  fetchMessageDetail: PropTypes.func.isRequired,
  likePercentage: PropTypes.number,
  likes: PropTypes.number,
  messageId: PropTypes.string,
  unlocks: PropTypes.number,
  price: PropTypes.number,
  viewCount: PropTypes.string,
  hasFetchedMessageDetail: PropTypes.bool,
};

WithMessageMetaData.defaultProps = {
  fetchMessageDetail: () => null,
  messageId: '',
  likePercentage: null,
  viewCount: '-',
  price: 0,
  hasFetchedMessageDetail: true,
};

export default WithMessageMetaData;
