// WithMessageMetaData.js
import { connect } from 'react-redux';
import fetchMessageDetail from '../action/fetchMessageDetail.js';
import getMessageData from '../selector/getMessageData.js';
import WithMessageMetaData from '../component/WithMessageMetaData.jsx';
import getNetworkingData from '../selector/getNetworkingData.js';

const mapStateToProps = (state, { messageId }) => {
  const likes = getMessageData(state, messageId, 'likes');
  const likePercentage = getMessageData(state, messageId, 'likePercentage');
  const unlocks = getMessageData(state, messageId, 'unlocks');
  const viewCount = getMessageData(state, messageId, 'viewCountWithDefault');
  const price = getMessageData(state, messageId, 'unlockPrice');
  return {
    likes,
    unlocks,
    likePercentage,
    viewCount,
    price,
    hasFetchedMessageDetail: !!getNetworkingData(
      state,
      ['messages', messageId, 'detail'],
      'isFetched'
    ),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchMessageDetail: ({ messageId }) =>
      dispatch(fetchMessageDetail({ id: messageId })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithMessageMetaData);
