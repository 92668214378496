// FlixPreview.js
import { connect } from 'react-redux';
import withRouter from '../component/WithRouter.jsx';

import {
  MESSAGE_BADGE_NONE,
  PREVIEW_PRESET,
  SHORT_VIDEO_DURATION_SECONDS,
} from '../RemoteConfigKeys.js';

import FlixPreview from '../component/FlixPreview.jsx';
import { replace } from '../action/navigationAction.js';
import fetchUser from '../action/fetchUser.js';
import fetchAssetManifest from '../action/fetchAssetManifest.js';
import getMessageData from '../selector/getMessageData.js';
import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';
import getMessageDuration from '../selector/getMessageDuration.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import getOperationData from '../selector/getOperationData.js';
import getAssetData from '../selector/getAssetData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';
import {
  MediaAssetFormat,
  MediaPreset,
  getAssetTrailer,
} from '../resource/getMediaAsset.js';

const mapStateToProps = (state, { messageId, location }) => {
  if (!messageId) {
    // We only need placeholder here, so let's skip the other calculation.
    return {};
  }
  const userId = messageId && getMessageData(state, messageId, 'senderId');
  const meId = getMeData(state, 'id');
  const thumbnailSource = getMessageThumbnail(
    state,
    messageId,
    '',
    '256x256',
    'jpg',
    'clear'
  );
  const blurredThumbnailSource = getMessageThumbnail(
    state,
    messageId,
    '',
    '256x256',
    'jpg',
    'blurred'
  );
  const isAuthed = !!getMeData(state, 'id');
  const isOnFreezone = getIsMatchedPathname({
    pathname: location?.pathname,
    dataKey: 'isOnFreezone',
  });
  const hasFreePeek = getMessageData(state, messageId, 'hasFreePeek');
  const assetIds = getMessageData(state, messageId, 'assetIds');
  const firstAssetId = assetIds?.[0];
  const meUsername = getMeData(state, 'username');
  const hasVerified = getRemoteConfigData(state, PREVIEW_PRESET) === 'clear';
  const trailerSource = getAssetTrailer({
    id: messageId,
    meId,
    userId,
    format: MediaAssetFormat.MP4,
    preset: hasVerified ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
    duration: 10,
  }).href;

  return {
    userId,
    username: getUserData(state, userId, 'username'),
    originalTitle: getMessageDataWithVariable(
      state,
      messageId,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    thumbnailSource,
    blurredThumbnailSource,
    trailerSource,
    drmRulesetTeststatus: getOperationData(state, ['drm'], 'testStatus'),
    contentType: getAssetData(state, [firstAssetId], 'contentType'),
    duration: getMessageDuration(state, messageId, location?.pathname),
    shortVideoDuration: getRemoteConfigData(
      state,
      SHORT_VIDEO_DURATION_SECONDS
    ),
    isAuthed,
    isOnFreezone,
    hasFreePeek,
    badges: getMessageData(state, messageId, 'badges'),
    assetIds,
    messageBadgeNotShowList: getRemoteConfigData(state, MESSAGE_BADGE_NONE),
    hasVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: userId => dispatch(fetchUser({ id: userId })),
    replace: data => dispatch(replace(data)),
    fetchAssetManifest: ({ messageId, assetId }) =>
      dispatch(fetchAssetManifest({ messageId, assetId })),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    // equals to `{ ...ownProps, ...stateProps, ...dispatchProps }`
    // ref: https://github.com/reduxjs/react-redux/blob/v7.1.0/src/connect/mergeProps.js
    null,
    { forwardRef: true } // Required by WithIntersectionObserver
  )(FlixPreview)
);
