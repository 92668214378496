// getMessageThumbnail.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import { PREVIEW_PRESET, PUBLIC_URL_PREFIX } from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getMeData from '../selector/getMeData.js';
import { getPoster, MediaPreset } from '../resource/getMediaAsset.js';
import getMessageData from '../selector/getMessageData.js';

/**
 * Select message thumbnail.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @param {string} assetId - asset id.
 * @param {string} [size = '128x128'] - message thumbnail size.
 * @param {string} [extension = 'jpg'] - message thumbnail extension.
 * @param {string} [preset] - message thumbnail preset. ex: 'clear', 'blurred'
 * @param {boolean} [shouldUseToken = false] - should set token on thumbnail url.
 * @return {string} Return message thumbnail url.
 */
const getMessageThumbnail = createCachedSelector(
  state => getMeData(state, 'token'),
  state => getRemoteConfigData(state, PREVIEW_PRESET),
  (state, messageId) => messageId,
  (state, messageId, assetId) => assetId,
  (state, messageId, assetId, size) => size,
  (state, messageId, assetId, size, extension) => extension,
  (state, messageId, assetId, size, extension, preset) => preset,
  (state, messageId, assetId, size, extension, preset, shouldUseToken) =>
    shouldUseToken,
  (state, messageId) => getMessageData(state, messageId, 'canViewMedia'),
  state => getRemoteConfigData(state, PUBLIC_URL_PREFIX),
  (state, messageId) => getMessageData(state, messageId, 'senderId'),
  state => getMeData(state, 'id'),
  (
    token,
    trailerPreset,
    messageId,
    assetId,
    size = '128x128',
    extension = 'jpg',
    preset,
    shouldUseToken = false,
    canViewMedia = false,
    // Use `PUBLIC_URL_PREFIX` as result function parameter to generate correct poster url on SSR.
    // This solve the issue in SSR where different users with different `PUBLIC_URL_PREFIX` try to generate a poster url, but they all end up using the poster URL directly from the cache.
    // eslint-disable-next-line no-unused-vars
    publicUrlPrefix,
    senderId,
    meId
  ) => {
    const usingPreset = preset
      ? preset
      : canViewMedia
        ? 'clear'
        : trailerPreset;
    const url = getPoster({
      id: messageId,
      assetId,
      userId: senderId,
      meId,
      size,
      format: extension,
      preset: usingPreset === 'clear' ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
    });
    if (shouldUseToken && token) {
      url.searchParams.set('auth', token);
    }
    return url.href;
  }
)(
  (
    state,
    messageId,
    assetId,
    size = '128x128',
    extension = 'jpg',
    preset,
    shouldUseToken = false
  ) =>
    `${messageId}:${assetId}:${size}:${extension}:${preset}:${shouldUseToken}`
);

export default getMessageThumbnail;
